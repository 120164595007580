import React from 'react';

class SignUp extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    window.location.replace('https://app.cpeu.org/signup');
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <>
        Redirecting to signup. If you are not automatically directed,{' '}
        <a href={'https://app.cpeu.org/signup'}>click here.</a>{' '}
      </>
    );
  }
}

export default SignUp;
